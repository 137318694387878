<template>
  <b-row v-if="program">
    <b-col sm="4">

      <router-link :to="{ name: 'champion-participants' }" style="color: unset" class="d-flex justify-content-center "> 
        <b-button
          variant="primary" class="mb-2 d-flex fluid  justify-content-center" style="width: 100%" >
          <feather-icon icon="PlusIcon" size="16" class="mr-50" />
          <span class="text-nowrap"> Invite</span>
        </b-button>
      </router-link>

      <b-card class="d-xs-block">
        <h4 class="mb-2">
          <b-row>
            <b-col cols="8" class="d-flex align-items-center">
              <feather-icon icon="ListIcon" size="20" />
              <b-card-title class="mb-0 ml-2">
                Licence Dates
              </b-card-title>
            </b-col>
          </b-row>
        </h4>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Start Date" label-for="licence-start-date">
              <div class="bag-button btn-common secondary-bg-color">
                  {{ programLicenceStartDate }}
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="End Date" label-for="licence-end-date">
              <div class="bag-button btn-common secondary-bg-color">
                  {{ programLicenceEndDate }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <!-- Program info -->
    <b-col sm="8">
      <b-row>
        <b-col>
          <program-alerts></program-alerts>
        </b-col>
      </b-row>

      <!-- Program info -->

      <!-- Active Learners -->
      <b-row>
        <b-col sm="6">
          <router-link :to="{ name: 'champion-participants' }" style="color: unset">
            <b-card tag="article">
              <b-row>
                <b-col class="d-flex flex-column justify-content-between">
                  <div>
                    <b-card-title class="text-truncate" style="margin-bottom: 3px">
                      Active Participants
                    </b-card-title>
                  </div>
                  <b-card-text class="mb-0 h1 font-weight-bold">
                    {{ activeParticipantsCount }} of {{ participantsCount }}
                  </b-card-text>
                </b-col>
                <b-col class="p-0">
                  <div id="chart">
                    <apexchart type="radialBar" :options="activeParticipantPercentageChartOption"
                      :series="activeParticipantPercentage" />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </router-link>
        </b-col>
        <b-col sm="6">
          <router-link :to="{ name: 'champion-participants' }" style="color: unset">
            <b-card tag="article">
              <b-row>
                <b-col class="d-flex flex-column justify-content-between">
                  <div>
                    <b-card-title class="text-truncate" style="margin-bottom: 3px">
                      Completion Overview
                    </b-card-title>
                  </div>
                  <b-card-text class="mb-0 h1 font-weight-bold">
                    {{ seatsCompletedCount }} of {{ seatsAllocatedCount  }}
                  </b-card-text>
                </b-col>
                <b-col class="p-0">
                  <div id="chart">
                    <apexchart type="radialBar" :options="seatsCompletedPercentageChartOption"
                      :series="seatsCompletedPercentage" />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <weekly-training-completions />
        </b-col>
      </b-row>
      <b-row class="d-sm-block">
        <b-col>
          <b-card>
            <b-card-title>
              Recent completion activity
            </b-card-title>

            <trainees-recent-completion-list />
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BAvatar,
  BFormGroup,
  BProgress,
  BButton
} from 'bootstrap-vue';
import { AOM_GREEN_COLOR,
  STATUS_COLOR
 } from "@/libs/utils";
import { userRoles } from '@models/userRoles';
import { matchStatus, programTypes } from '@models';
import { localeDateStringFromIsoDateTime } from "@/libs/utils";
import ProgramAlerts from './ProgramAlerts.vue';
import { roleAlias } from "@/@aom-core/utils/utils";
import TraineesRecentCompletionList from './TraineesRecentCompletionList.vue';
import WeeklyTrainingCompletions from "../../reports/WeeklyTrainingCompletions.vue";
export default {
  name: 'ChampionsTrainingDashboard',
  components: {
    BRow,
    BCol,
    BBadge,
    BCard,
    BFormGroup,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BAvatar,
    BProgress,
    BButton,
    ProgramAlerts,
    TraineesRecentCompletionList,
    WeeklyTrainingCompletions
  },
  data() {
    return {

      matchCapacityChartData: [10],
      participantsChartData: [100],
      defaultChartColor: "#FFA500",
      defaultGreenChartColor: AOM_GREEN_COLOR,
      defaultChartOptions: {
        chart: {
          type: 'radialBar',
          renderTo: 'container',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          offsetY: 0,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '70%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            },
            track: {
              strokeWidth: '95%',
              margin: 0,
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                formatter: function(val) {
                  return parseInt(val) + "%";
                },
                color: '#003366',
                fontSize: '20px',
                lineHight: '1',
                show: true,
                offsetY: 6,
                offsetX:0,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
      },
      userRoles,
      matchStatus
    };
  },

  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    programTimezone() {
      if (this.defaultProgram && this.defaultProgram.time_zone) {
        return this.defaultProgram.time_zone;
      }
      return '';
    },
    program() {
      if (this.defaultProgram) {
        return this.defaultProgram;
      }

      return null;
    },

    activeParticipantsCount() {
      if (!this.program) { return 0; }
      return this.program.active_participants_count || 0;
    },

    participantsCount() {
      if (!this.program) { return 0; }
      return this.program.participants_count || 0;
    },

    activeParticipantPercentage() {
      if (this.participantsCount === 0) { return [0]; }

      return [Math.round(this.activeParticipantsCount * 100 / this.participantsCount)];
    },
    

    activeParticipantPercentageChartOption() {
      if (this.activeParticipantPercentage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
    },

    seatsCompletedPercentageChartOption() {
      if (this.seatsCompletedPercentage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
    },


    seatsCompletedCount() {
      if (!this.program) { return 0; }
      return this.program.seats_completed_count || 0;
    },

    seatsAllocatedCount() {
      if (!this.program) { return 0; }

      return this.program.seats_allocated_count;
    },

    seatsCompletedPercentage() {
      if (!this.program) { return 0; }

      return this.seatsAllocatedCount? [Math.round(this.seatsCompletedCount * 100 / this.seatsAllocatedCount)]: [0];
    },

    programLicenceStartDate() {
      return localeDateStringFromIsoDateTime(this.program?.licence_start_date);
    },

    programLicenceEndDate() {
      return localeDateStringFromIsoDateTime(this.program?.licence_end_date);
    },
  },

  methods: {
    getPercentage(achieved, total) {
      if (!achieved) {
        return 0;
      }
      return (
        Math.round(
          (achieved * 100) /
            total
        ) || 0
      );
    },
  },
  setup() {
    return {
      STATUS_COLOR,
      roleAlias
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .default-chart-color {
  .progress-bar {
    background-color: #FFA500 !important;
  }
}
::v-deep .default-green-chart-color {
  .progress-bar {
    background-color: #9bcc65 !important;
  }
}
</style>