<template>
  <div>
    <!-- table -->
    <vue-good-table
      ref="refTraineesRecentCompletionListTable"
      class="position-relative"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      responsive
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BBadge,
  BButton,
  VBToggle,
  BNavItemDropdown,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
  localDateStringOrDateRangeStringToUtcRange
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { STATUS_COLOR } from "@/libs/utils";
import trainingsService from "@/services/trainingsService";
import { userStatusDisplay, userStatusSelect } from "@models/userStatus";
import EditChampion from "@/views/apps/admin/champion/EditChampion";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import SuspendChampion  from '@/views/apps/admin/champion/actions/SuspendChampion.vue';
import RemoveChampion from '@/views/apps/admin/champion/actions/RemoveChampion.vue';
import EnableTfaChampion from '@/views/apps/admin/champion/actions/EnableTfaChampion.vue';
import ResendAccessChampion from '@/views/apps/admin/champion/actions/ResendAccessChampion.vue';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  name: 'TraineesRecentCompletionList',
  components: {
    VueGoodTable,
    BBadge,
    BButton,
    BNavItemDropdown,
    TablePagination,
    EditChampion,
    SuspendChampion,
    RemoveChampion,
    EnableTfaChampion,
    ResendAccessChampion,
    BLink
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "full_name",
          tdClass: "text-nowrap",
        },
        {
          label: "Module",
          field: "training_name",
          
        },
        {
          label: "Completed on",
          field: "completed_date",
          tdClass: "search-date-selector",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters("app", ["isChampionPortal"]),
    ...mapGetters("profile", ['profile', 'isProgramAdmin']),

    hasProgramAdminRole() {
      return this.isProgramAdmin(Number(this.routeProgramId));
    },
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    routeProgramId() {
      return this.$route.params.id;
    }
  },
  watch: {
    routeProgramId(_n, o) {
      if (!o) {
        return;
      }
      this.loadItems();
    }
  },
  async created() {
    await this.loadItems();
  },
  mounted() {
    const elements = document.getElementsByName("vgt-created_at");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];

      for (let col of ["status", "full_name", "email", "phone", "created_at", "roleText"]) {
        if (params.columnFilters[col]) {
          if (col === "roleText") {
            let roleIds = [];
            roleIds.push(params.columnFilters.roleText);
            columnFilters.push({
              field: 'role',
              value: roleIds,
            });
          } else if (col === "created_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        if (!this.routeProgramId) {
          return;
        }
        const response = await trainingsService.getRecentlyCompletedTraining(
          this.routeProgramId,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          full_name: item.full_name,
          training_name: item.training_name,
          completed_date: item.completed_date,
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Champions list not loaded."));
      } finally {
        this.isLoading = false;
      }
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      STATUS_COLOR,
      total, 
      perPage, 
      page
    };
  },
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
</style>

<style scoped>
.b-nav-dropdown {
  display: flex;
}
</style>
