var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{ref:"refTraineesRecentCompletionListTable",staticClass:"position-relative",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"responsive":"","rows":_vm.rows,"rtl":_vm.directionIsRTL,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"sort-options":{
      enabled: false,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.perPage,
    },"style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }