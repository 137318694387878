<template>
  <section>
    <b-card>
      <b-row>
        <b-col sm="4">
          <h4 class="text-left">
            Weekly Learning Progress
          </h4>
        </b-col>
      </b-row>
      
      <aom-skeleton-loader v-if="isLoading" />
      <section
        v-else
        class="mt-2"
      >
        <apexchart
          type="line"
          :options="options"
          :series="options.series"
          height="350px"
        />
      </section>
    </b-card>
  </section>
</template>
    
<script>
import { 
  BButton,
  BCard,
  BFormDatepicker,
  BCol,
  BRow,
  BFormInvalidFeedback,
  BFormGroup,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import {reportService, groupsService }  from "@/services";
import { mapGetters } from 'vuex';
import { STATUS_COLOR } from "@/libs/utils";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { makeErrorToast } from "@/libs/utils";
import { ValidationProvider } from "vee-validate";
//eslint-disable-next-line
import vSelect from "vue-select";     
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import {
  getValidationState,
  localeDateStringFromIsoDateTime
} from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
  
export default {
  name: 'WeeklyTrainingCompletions',
  components: {
    BButton,
    BCard,
    VueGoodTable,
    TablePagination,
    AomSkeletonLoader,
    BFormDatepicker,
    ValidationProvider,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      downLoadsThisMonth: 0,
      deltaDownloadsCount: 0,
      isLoading: false,
      year: undefined,
      month: undefined,
      fromDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      toDate: new Date(),
      selectedGroups: [],
      programGroups: [],
      options: {
        
        colors: ["#9bcc65"],
        series: [],
        xaxis: {},
        fontWeight: 300,
        yaxis: {
          title: {
            text: 'Modules started and completed',
            style: {
              fontSize:  '13px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
          },
          labels: {
            formatter: function(val) {
              return Math.floor(val);
            }
          }
        },
        chart: {
          height: 240,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1,
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isRtl() {
      return store.state.appConfig.isRTL;
    },
  },
  watch: {
    defaultProgram: {
      handler(n) {
        if(n) {
          this.loadPageData();
        }
      },
      deep: true,
      immediate: true
    },
    selectedGroups(n) {
      if(n) {
        this.loadChartTableData();       
      }
    },
    fromDate(n) {
      if(n) {
        this.loadChartTableData();
      }
    },
    toDate(n) {
      if(n) {
        this.loadChartTableData();
      }
    }
  },
  methods: {
    async loadPageData() {
      this.fromDate = this.defaultProgram?.licence_start_date;
      try {
        this.isLoading = true;
        await Promise.all([
          this.loadChartData(),
          this.loadProgramGroup()
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async loadChartTableData() {
      try {
        this.isLoading = true;
        await Promise.all([
          this.loadChartData()
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async loadChartData() {
      try {
        const programId = this.$route.params.id;
        const response = await reportService.getWeeklyTraineeCompletionChartData(programId, {}, this.toDate, this.fromDate, [] );
        const {data, x_data} = response.data;
        this.options.series =  [{name: "Total users who started training", data }]; 
        // eslint-disable-next-line camelcase
        this.options = {
          ...this.options,
          xaxis: {
            ...this.options.xaxis,
            max: x_data.length,
            categories: x_data
          }
        };
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }
    },
    async loadProgramGroup() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await groupsService.listGroups(programId);
        const {data} = response;
        const {items} = data;
        this.programGroups = items;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      STATUS_COLOR,
      localeDateStringFromIsoDateTime,
      getValidationState,
      total, perPage, page
    };
  }
};
</script>
    
    <style lang="scss">
    @import '@/assets/scss/vue/libs/vue-good-table.scss';
    </style>
    <style lang="scss" scoped>
    .group-filter {
      width: 25%;
      min-width: 25%;
    }
    ::v-deep .apexcharts-menu-item.exportCSV {
      display: none;
    }
    </style>
    