<template>
  <div>
    <champions-training-dashboard
      v-if="isProgramTraining"
    />
    <champions-mentorship-dashboard
      v-if="!isProgramTraining"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { programTypes } from '@models';
import ChampionsMentorshipDashboard from './components/ChampionsMentorshipDashboard.vue';
import ChampionsTrainingDashboard from './components/ChampionsTrainingDashboard.vue';

export default {
  name: 'ChampionsDashboard',
  components: {
    ChampionsMentorshipDashboard,
    ChampionsTrainingDashboard
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },
};
</script>